html,
body,
#root {
  scrollbar-color: auto !important;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', 'Arial' !important;
  font-weight: 400 !important;
  position: relative;
}

main {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

::selection {
  color: white;
  background: #3081fa;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #b9b9bb;
  border-radius: 6px;
  border: 4px solid white;
}

*::-webkit-scrollbar-thumb:hover {
  border-width: 2px;
}

.transparent-bg {
  background: url('./transparent.png');
  object-fit: contain;
  background-size: contain;
}

#webpack-dev-server-client-overlay,
#webpack-dev-server-client-overlay-div {
  display: none !important;
}
